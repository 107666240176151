<template>
    <div class="from_outer">
      <div>
        <el-form
          ref="formName"
          label-width="180px"
          :rules="rules"
          :model="formLabelAlign"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="车主姓名："
                prop="ower_name"
                :rules="[{ required: true, message: '请输入车主姓名' }]"
              >
                <el-input
                  placeholder="请输入车主姓名"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.ower_name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="所属部门："
                prop="department_id"
                :rules="[{ required: true, message: '请选择所属部门' }]"
              >
                <Bepartment
                  ref="Bepartment"
                  class="form-inp"
                  v-model="formLabelAlign.department_id"
                />
              </el-form-item>
            </el-col>
  
            <el-col :span="12">
              <el-form-item label="车主手机号：" prop="ower_phone">
                <el-input
                  placeholder="请输入手机号"
                  size="mini"
                  class="form-inp"
                  v-model.number="formLabelAlign.ower_phone"
                ></el-input>
              </el-form-item>
            </el-col>
 
            <el-col :span="12">
              <el-form-item
                label="车主证件号："
                prop="idcard"
                :rules="[{ required: true, message: '车主证件号：' }]"
              >
                <el-input
                  placeholder="车主证件号："
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.idcard"
                ></el-input>
              </el-form-item>
            </el-col>  
            <el-col :span="12">
              <el-form-item label="车辆类型：" prop="car_type">
                <el-cascader
                  class="form-inp"
                  v-model="formLabelAlign.car_type"
                  :options="car_type_list"
                  :props="{
                    checkStrictly: true,
                    label: 'name',
                    value: 'id',
                  }"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="车牌号" prop="car_no">
                <el-input
                  placeholder="行车码"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.car_no"
                ></el-input>
              </el-form-item>
            </el-col> 
            <el-col :span="12">
                <el-form-item label="行车码" prop="car_codeid">
                    <el-select
                        v-model="formLabelAlign.car_codeid"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入关键词"
                        :remote-method="getCarCodeList"
                        :loading="loading">
                        <el-option
                        v-for="item in carcode_list"
                        :key="item.id"
                        :label="item.code"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
              <!-- <el-form-item label="行车码" prop="car_codeid">
                <el-input
                  placeholder="行车码"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.car_codeid"
                ></el-input>
              </el-form-item> -->
            </el-col>
            <el-col :span="12">
              <el-form-item label="违章次数" prop="irr_num">
                <el-input
                  placeholder="违章次数"
                  size="mini"
                  class="form-inp"
                  v-model="formLabelAlign.irr_num"
                ></el-input>
              </el-form-item>
            </el-col>
  
          </el-row>
        </el-form>
      </div>
  
      <div style="text-align: center">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
      <!-- 风险源标签 -->
    </div>
  </template>
  
  <script>
  import {
    carSaveInfo,
    carSave,
    getCarCodeList
  } from "../../assets/request/api";
  import Bepartment from "../../components/Bepartment";
  export default {
    name: "carredact",
    components: {
      Bepartment,
    },
    props: {
      dialog_show: {
        type: Boolean,
      },
    },
    data() {
      return {
        type_name: "保存",
        //要修改的id
        id: "",
        options: [],
        check_times: [],
        bepartment_Data: [],
        estimate_type: [],
        color_level: [],
        status_list: "",
        formLabelAlign: {
          department_id: 0,
          ower_name: "",
          ower_phone: "",
          car_codeid: 0,
          car_no: "",
          idcard: "",
          irr_num: 0
        },
        rules: {
        },
        car_type_list:[],
        carcode_list:[]       
      };
    },
    created() {
    //   this.way_labelList();
    },
    methods: {
      way_show(id) {
        let froms = {};
        if (id) froms.id = id;
        carSaveInfo(froms).then((res) => {
          let {
            info,
            car_type_list,
            carcode_list
          } = res.data;
          this.car_type_list = car_type_list;
          this.carcode_list = carcode_list;
          if (id) {
            this.id = id;
            this.type_name = "修改";
            let { formLabelAlign } = this;
            let {
              ower_name,
              ower_phone,
              car_codeid,
              idcard,
              car_no,
              irr_num,
              department_id,
              car_type,
            } = info;
            formLabelAlign.id = id;
            formLabelAlign.ower_name = ower_name;
            this.$refs.Bepartment.bepartment_List = department_id;
            formLabelAlign.department_id = department_id;
            formLabelAlign.ower_phone = ower_phone;
            formLabelAlign.car_no = car_no;
            formLabelAlign.irr_num = irr_num;
            formLabelAlign.car_type = car_type;
            formLabelAlign.idcard = idcard;
            formLabelAlign.car_codeid = car_codeid;

          }
        });
      },
      getCarCodeList(query){
        if (query !== '') {
          this.loading = true;
          getCarCodeList({code:query}).then((res)=>{
            this.loading = false;
            this.carcode_list =res.data;
          })
        } else {
          this.carcode_list = [];
        }

      },
      on_timeData() {
        let { timeData, formLabelAlign } = this;
        if (timeData == null) {
          formLabelAlign.begin_time = "";
          formLabelAlign.end_time = "";
        } else {
          formLabelAlign.begin_time = timeData[0];
          formLabelAlign.end_time = timeData[1];
        }
      },
      on_change(val) {
      },
      dataChange(event) {
        let events = event[event.length - 1];
        this.formLabelAlign.cate_id = events;
      },
      
      //点击保存
      on_preserve(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign));

            carSave(formLabelAlign).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                let that = this;
                that.$emit("way_tableData");
                setTimeout(function () {
                  that.$emit("update:refbool", false);
                }, 1000);
              }
            });
          } else {
            return false;
          }
        });
      },
      //返回
      route_go() {
        this.$emit("update:refbool", false);
      },
    },
   
  };
  </script>
  
  <style lang="less" scoped>
  .from {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .form-inp {
    width: 200px;
    height: 32px;
  }
  .pop-inp {
    width: 150px;
  }
  .tag-sty {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  .selectlength {
    color: #515a6e;
    border: 1px solid #abdcff;
    background-color: #f0faff;
    padding: 10px 20px;
    font-size: 14px;
    margin: 20px 0;
    border: 5px;
    span {
      color: rgb(45, 140, 240);
    }
  }
  .search_button {
    display: flex;
    margin-bottom: 18px;
    flex-direction: column-reverse;
  }
  .botton-an {
    text-align: center;
    margin-top: 10px;
  }
  .pop-img {
    margin: 0 5px 10px 0;
    width: 40px;
    height: 40px;
  }
  .form-textarea {
    width: 400px;
    margin-bottom: 10px;
  }
  .execute {
    display: flex;
  }
  
  .execute /deep/ .el-input__inner {
    height: 32px;
    margin-top: 1px;
  }
  .el-col {
    height: 50px;
  }
  </style>